/* eslint-disable vue/max-len */
import { format } from 'date-fns'
import { configApi } from '../../../http-client.js'
import { DATE_FORMAT } from '../../../date-formatting.js'

const formatLinkResponse = linkResponse => {
	const { data } = linkResponse
	return ({
		data: {
			...data,
			actionLinks: data.actionLinks.map(actionLink => ({
				...actionLink,
				AllowHTTPPostOnly: actionLink.enableOneClickUnsubscribe,
				actionLinkEnforceDoubleOptIn: actionLink.enableActionLinkEnforceDoubleOptIn,
				actionLinkConfig: {
					...actionLink.actionLinkConfig,
					extendedPreference: actionLink.actionLinkConfig.extendedPrefs.map(extPref => ({
						...extPref,
						emailLinkId: actionLink.actionLinkId,
						channelId: extPref?.channelId,
						statementId: extPref?.statementId,
						extendedPreferenceKeyId: extPref?.keyId,
						freeTextValue: extPref?.defaultFreeTextValue
					}))
				},
				customPrivacyPolicies: actionLink.customPrivacyPolicies.map(customPolicy => ({
					...customPolicy,
					privacyPolicyVersionId: customPolicy.privacyPolicyVersionId ? customPolicy.privacyPolicyVersionId : 'Always Use Latest Version',
					privacyPolicyExternalVersionRef: customPolicy.externalVersionRef
				})),
				configurationOptions: {
					...actionLink.configurationOptions,
					ipLimitations: {
						...actionLink.configurationOptions.ipLimitations,
						ipLimitPeriodInMins: actionLink.configurationOptions.ipLimitations.ipLimitPeriodInMins,
						limitSubmissionsByIpTypeId: actionLink.configurationOptions.ipLimitations.limitSubmissionsByIpTypeId === 2
					}
				},
				linkFields: actionLink.linkFields.map(linkField => ({
					friendlyNameInLink: linkField.friendlyNameInLink,
					isEncrypted: linkField.isEncrypted,
					isMatchField: linkField.isMatchField,
					replaceValueInLink: linkField.replaceValueInLink,
					standdakFieldName: linkField.standdakFieldName.toLowerCase(),
					previewValue: linkField.previewValue
				})),
				embedLink: actionLink.actionLinkEmbedCode,
				linkText: actionLink.linkText,
				formattedCreatedDate: format(new Date(actionLink.createdDate), DATE_FORMAT),
				formattedLastEditedDate: format(new Date(actionLink.lastEditedDate), DATE_FORMAT)
			})),
			preferencePageLinks: data.preferencePageLinks.map(preferencePageLink => ({
				...preferencePageLink,
				customPrivacyPolicies: preferencePageLink.customPrivacyPolicies.map(customPolicy => ({
					...customPolicy,
					privacyPolicyVersionId: customPolicy.privacyPolicyVersionId ? customPolicy.privacyPolicyVersionId : 'Always Use Latest Version',
					privacyPolicyExternalVersionRef: customPolicy.externalVersionRef
				})),
				linkFields: preferencePageLink.linkFields.map(linkField => ({
					friendlyNameInLink: linkField.friendlyNameInLink,
					isEncrypted: linkField.isEncrypted,
					isMatchField: linkField.isMatchField,
					replaceValueInLink: linkField.replaceValueInLink,
					standdakFieldName: linkField.standdakFieldName.toLowerCase(),
					previewValue: linkField.previewValue
				})),
				preferencePageLinkConfig: {
					...preferencePageLink.preferencePageLinkConfig,
					pageSettings: {
						...preferencePageLink.preferencePageLinkConfig.pageSettings,
						showChannelName: preferencePageLink.preferencePageLinkConfig.showChannelName
					},
					extendedPrefs: preferencePageLink.preferencePageLinkConfig.extendedPrefs.map(extPref => ({
						...extPref,
						extendedPreferenceName: extPref.keyName
					}))
				},
				configurationOptions: {
					...preferencePageLink.configurationOptions,
					ipLimitations: {
						...preferencePageLink.configurationOptions.ipLimitations,
						ipLimitPeriodInMins: preferencePageLink.configurationOptions.ipLimitations.ipLimitPeriodInMins,
						limitSubmissionsByIpTypeId: preferencePageLink.configurationOptions.ipLimitations.limitSubmissionsByIpTypeId === 2
					}
				},
				embedLink: preferencePageLink.preferencePageLinkEmbedCode,
				formattedCreatedDate: format(new Date(preferencePageLink.createdDate), DATE_FORMAT),
				formattedLastEditedDate: format(new Date(preferencePageLink.lastEditedDate), DATE_FORMAT)
			})),
			publicPortalLinks: data.publicPortalLinks.map(publicPortalLink => ({
				...publicPortalLink,
				linkFields: publicPortalLink.linkFields.map(linkField => ({
					friendlyNameInLink: linkField.friendlyNameInLink,
					isEncrypted: linkField.isEncrypted,
					isMatchField: linkField.isMatchField,
					replaceValueInLink: linkField.replaceValueInLink,
					standdakFieldName: linkField.standdakFieldName.toLowerCase(),
					previewValue: linkField.previewValue
				})),
				configurationOptions: {
					...publicPortalLink.configurationOptions,
					ipLimitations: {
						...publicPortalLink.configurationOptions.ipLimitations,
						ipLimitPeriodInMins: publicPortalLink.configurationOptions.ipLimitations.ipLimitPeriodInMins,
						limitSubmissionsByIpTypeId: publicPortalLink.configurationOptions.ipLimitations.limitSubmissionsByIpTypeId === 2
					}
				},
				embedLink: publicPortalLink.publicPortalLinkEmbedCode,
				formattedCreatedDate: format(new Date(publicPortalLink.createdDate), DATE_FORMAT),
				formattedLastEditedDate: format(new Date(publicPortalLink.lastEditedDate), DATE_FORMAT)
			}))
		}
	})
}
export const getLinks = () => configApi.get('/api/Links').then(formatLinkResponse)

export const getActionLinks = () => configApi.get('/api/Links/Action').then(({ data }) => data.links)

export const getActionLinkEmbedCode = linkId =>
	configApi.get(`/api/Links/Action/Embed/${linkId}`).then(({ data }) => data)

export const getActionLinkEmbedCodePreview = linkId =>
	configApi.get(`/api/Links/Action/Embed/Preview/${linkId}`).then(({ data }) => data)

export const getLinkByID = linkID => configApi.get(`/api/Links/${linkID}`).then(formatLinkResponse)

export const deleteLink = link =>
	configApi.delete(`/api/Links?linkId=${link.linkId}&linkTypeId=${link.linkTypeId}`)

export const upsertActionLink = link => {
	const FORWARD_TO_FAILURE_PAGE = 2
	if (link.failureType === FORWARD_TO_FAILURE_PAGE) {
		link.customErrorUrl = ''
	}

	if (!link.configurationOptions.ipLimitations) {
		link.configurationOptions.ipLimitations = {
			noOfSubmissionsAllowedFromIp: null,
			ipLimitPeriodInMins: null,
			limitSubmissionsByIpTypeId: false
		}
	}

	if (!link.configurationOptions.encryption) {
		link.configurationOptions.encryption = {
			encryptionKeyId: null
		}
	}
	delete link.actionLinkConfig.extendedPrefs
	const actionLink = {
		actionLinkId: link.actionLinkId ? link.actionLinkId : null,
		linkName: link.linkName,
		linkText: link.linkText,
		footerContentId: link.footerContentId,
		brandId: link.brandId,
		isEnabled: link.isEnabled ?? true,
		AllowHTTPPostOnly: link.enableOneClickUnsubscribe ?? false,
		actionLinkEnforceDoubleOptIn: link.actionLinkEnforceDoubleOptIn,
		customDefaultLanguageId: link.customDefaultLanguageId,
		customPrivacyPolicies: link.customPrivacyPolicies
			? link.customPrivacyPolicies.map(customPolicy => ({
				languageId: customPolicy.languageId,
				privacyPolicyId: customPolicy.privacyPolicyId,
				privacyPolicyVersionId: customPolicy.privacyPolicyVersionId === 'Always Use Latest Version' ? null : customPolicy.privacyPolicyVersionId
			}))
			: [],
		customErrorUrl: link.customErrorUrl ? link.customErrorUrl : null,
		themeId: link.themeId,
		authenticationDetails: {
			authTypeId: link.authenticationDetails.authTypeId,
			verificationSentMessage: link.authenticationDetails.verificationSentMessage ? link.authenticationDetails.verificationSentMessage : null,
			standDakAuthField: link.authenticationDetails.standDakAuthField ? link.authenticationDetails.standDakAuthField : null,
			standDakAuthFieldFriendlyName: link.authenticationDetails.standDakAuthFieldFriendlyName ? link.authenticationDetails.standDakAuthFieldFriendlyName : null,
			verificationAreaText: link.authenticationDetails.verificationAreaText ? link.authenticationDetails.verificationAreaText : null,
			verificationFailedMessage: link.authenticationDetails.verificationFailedMessage ? link.authenticationDetails.verificationFailedMessage : null,
			proveConfirmationEmailId: link.authenticationDetails.proveConfirmationEmailId ? link.authenticationDetails.proveConfirmationEmailId : null
		},
		propertyFields: {
			...link.propertyFields
		},
		linkFields: [
			...link.linkFields
		],
		configurationOptions: {
			createStanddakIfNoMatch: link.configurationOptions.createStanddakIfNoMatch,
			multiStandDakMatchIsFailure: link.configurationOptions.multiStandDakMatchIsFailure,
			ipLimitations: {
				noOfSubmissionsAllowedFromIp: link.configurationOptions.ipLimitations.noOfSubmissionsAllowedFromIp,
				ipLimitPeriodInMins: link.configurationOptions.ipLimitations.ipLimitPeriodInMins,
				limitSubmissionsByIpTypeId: link.configurationOptions.ipLimitations.limitSubmissionsByIpTypeId ? 2 : 1
			},
			encryption: {
				encryptionKeyId: link.configurationOptions.encryption.encryptionKeyId ? link.configurationOptions.encryption.encryptionKeyId : null
			},
			successOutcome: {
				confirmationPageTypeId: link.configurationOptions.successOutcome.confirmationPageTypeId,
				externalConfirmationPageUrl: link.configurationOptions.successOutcome.confirmationPageTypeId === 1 ? link.configurationOptions.successOutcome.externalConfirmationPageUrl : null,
				internalConfirmationPageId: link.configurationOptions.successOutcome.confirmationPageTypeId === 2 ? link.configurationOptions.successOutcome.internalConfirmationPageId : null
			},
			actionLinkConfig: {
				...link.actionLinkConfig,
				extendedPreference: link.actionLinkConfig.extendedPreference?.map(extPref => ({
					emailLinkID: link.actionLinkId ? link.actionLinkId : null,
					channelID: extPref.channelId,
					statementID: link.actionLinkConfig.actionLinkStatementId,
					extendedPreferenceKeyID: extPref.keyId,
					freeTextValue: extPref.defaultFreeTextValue
				})
				) ?? []
			}
		}
	}
	if (link.actionLinkId) {
		delete actionLink.brandId
	}
	const authenticationType = {
		Direct: 1,
		ConfirmValue: 2,
		ProveEmail: 3,
		ProvideValue: 4,
		ProvideAndProveEmail: 5
	}
	if (actionLink.authenticationDetails.authTypeId !== authenticationType.ProveEmail && actionLink.authenticationDetails.authTypeId !== authenticationType.ProvideAndProveEmail) {
		delete actionLink.authenticationDetails.proveConfirmationEmailId
	}
	return configApi.post('/api/Links/ActionUpsert', actionLink)
}

export const upsertPreferencePageLink = link => {
	const FORWARD_TO_FAILURE_PAGE = 2
	if (link.failureType === FORWARD_TO_FAILURE_PAGE) {
		link.customErrorUrl = ''
	}

	if (!link.configurationOptions.ipLimitations) {
		link.configurationOptions.ipLimitations = {
			noOfSubmissionsAllowedFromIp: null,
			ipLimitPeriodInMins: null,
			limitSubmissionsByIpTypeId: false
		}
	}

	if (!link.configurationOptions.encryption) {
		link.configurationOptions.encryption = {
			encryptionKeyId: null
		}
	}

	if (!link.preferencePageLinkConfig.pageSettings) {
		link.preferencePageLinkConfig.pageSettings = {}
	}

	/* 10128 - this is causing the array to be limited to 1 item, unsure why or its purpose, so commenting out for now
		link.preferencePageLinkConfig.pagePreferences = link.preferencePageLinkConfig.pagePreferences.filter((statement, index, self) =>
		index === self.findIndex(t => (
			t.id === statement.id
		))
	) */
	link.preferencePageLinkConfig.pagePreferences.forEach((pagePreference, index) => {
		pagePreference.displayOrderInPreferenceArea = index + 1
	})

	const authenticationType = {
		Direct: 1,
		ConfirmValue: 2,
		ProveEmail: 3,
		ProvideValue: 4,
		ProvideAndProveEmail: 5
	}
	const preferencePageLink = {
		preferencePageLinkId: link.preferencePageLinkId ? link.preferencePageLinkId : null,
		linkName: link.linkName,
		footerContentId: link.footerContentId,
		customDefaultLanguageId: link.customDefaultLanguageId,
		linkText: link.linkText,
		brandId: link.brandId,
		isEnabled: link.isEnabled ?? true,
		customPrivacyPolicies: link.customPrivacyPolicies
			? link.customPrivacyPolicies.map(customPolicy => ({
				languageId: customPolicy.languageId,
				privacyPolicyId: customPolicy.privacyPolicyId,
				privacyPolicyVersionId: customPolicy.privacyPolicyVersionId === 'Always Use Latest Version' ? null : customPolicy.privacyPolicyVersionId
			}))
			: [],
		customErrorUrl: link.customErrorUrl ? link.customErrorUrl : null,
		themeId: link.themeId,
		authenticationDetails: {
			authTypeId: link.authenticationDetails.authTypeId,
			verificationSentMessage: link.authenticationDetails.verificationSentMessage ? link.authenticationDetails.verificationSentMessage : null,
			standDakAuthField: link.authenticationDetails.authTypeId === authenticationType.ConfirmValue || link.authenticationDetails.authTypeId === authenticationType.ProvideValue ? link.authenticationDetails.standDakAuthField ? link.authenticationDetails.standDakAuthField : null : null,
			standDakAuthFieldFriendlyName: link.authenticationDetails.authTypeId !== authenticationType.Direct ? link.authenticationDetails.standDakAuthFieldFriendlyName : null,
			verificationAreaText: link.authenticationDetails.verificationAreaText ? link.authenticationDetails.verificationAreaText : null,
			verificationFailedMessage: link.authenticationDetails.verificationFailedMessage ? link.authenticationDetails.verificationFailedMessage : null,
			proveConfirmationEmailId: link.authenticationDetails.proveConfirmationEmailId ? link.authenticationDetails.proveConfirmationEmailId : null
		},
		linkFields: [
			...link.linkFields
		],
		propertyFields: {
			...link.propertyFields
		},
		configurationOptions: {
			createStandDakIfNoMatch: link.configurationOptions.createStanddakIfNoMatch,
			multiStandDakMatchIsFailure: link.configurationOptions.multiStandDakMatchIsFailure,
			ipLimitations: {
				noOfSubmissionsAllowedFromIp: link.configurationOptions.ipLimitations.noOfSubmissionsAllowedFromIp,
				ipLimitPeriodInMins: link.configurationOptions.ipLimitations.ipLimitPeriodInMins,
				limitSubmissionsByIpTypeId: link.configurationOptions.ipLimitations.limitSubmissionsByIpTypeId ? 2 : 1
			},
			encryption: {
				encryptionKeyId: link.linkFields.some(({ isEncrypted }) => isEncrypted) ? link.configurationOptions?.encryption?.encryptionKeyId : null
			},
			successOutcome: {
				confirmationPageTypeId: link.configurationOptions.successOutcome.confirmationPageTypeId,
				externalConfirmationPageUrl: link.configurationOptions.successOutcome.confirmationPageTypeId === 1 ? link.configurationOptions.successOutcome.externalConfirmationPageUrl : null,
				internalConfirmationPageId: link.configurationOptions.successOutcome.confirmationPageTypeId === 2 ? link.configurationOptions.successOutcome.internalConfirmationPageId : null
			}
		},
		preferencePageLinkConfig: {
			internalPageDescription: null,
			showChannelName: link.preferencePageLinkConfig.pageSettings.showChannelName === true,
			pageContents: {
				pageTitle: 'pageTitle' in link.preferencePageLinkConfig.pageContents ? link.preferencePageLinkConfig.pageContents.pageTitle : null,
				dataSubjectIdentifierDisplayField: 'dataSubjectIdentifierDisplayField' in link.preferencePageLinkConfig.pageContents ? link.preferencePageLinkConfig.pageContents.dataSubjectIdentifierDisplayField : null,
				headerId: 'headerId' in link.preferencePageLinkConfig.pageContents ? link.preferencePageLinkConfig.pageContents.headerId : null,
				pageText: 'pageText' in link.preferencePageLinkConfig.pageContents ? link.preferencePageLinkConfig.pageContents.pageText : null,
				mainPreferencesAreaTitle: 'mainPreferencesAreaTitle' in link.preferencePageLinkConfig.pageContents ? link.preferencePageLinkConfig.pageContents.mainPreferencesAreaTitle : null,
				mainPreferencesAreaText: 'mainPreferencesAreaText' in link.preferencePageLinkConfig.pageContents ? link.preferencePageLinkConfig.pageContents.mainPreferencesAreaText : null,
				subPreferenceArea1Title: 'subPreferenceArea1title' in link.preferencePageLinkConfig.pageContents ? link.preferencePageLinkConfig.pageContents.subPreferenceArea1title : null,
				subPreferenceArea2Title: 'subPreferenceArea2title' in link.preferencePageLinkConfig.pageContents ? link.preferencePageLinkConfig.pageContents.subPreferenceArea2title : null,
				footerId: 'footerId' in link.preferencePageLinkConfig.pageContents ? link.preferencePageLinkConfig.pageContents.footerId : null
			},
			pageSettings: {
				hideCurrentPrefData: link.preferencePageLinkConfig.pageSettings.hideCurrentPrefData,
				onlyAllowPreferenceOptOuts: link.preferencePageLinkConfig.pageSettings.onlyAllowPreferenceOptOuts,
				preferenceOptionsAreTrinary: link.preferencePageLinkConfig.pageSettings.preferenceOptionsAreTrinary,
				hideNoChangePreferenceOption: !link.preferencePageLinkConfig.pageSettings.preferenceOptionsAreTrinary
			},
			pagePreferences: link.preferencePageLinkConfig.pagePreferences.map(pagePreference => (
				{
					preferencesAreaId: pagePreference.preferencesAreaId,
					statementId: pagePreference.statementId,
					displayOrderInPreferenceArea: pagePreference.displayOrderInPreferenceArea,
					enforceDoubleOptIn: pagePreference.enforceDoubleOptIn
				}
			)),
			extendedPrefs: link.preferencePageLinkConfig.extendedPrefs?.map(extendedPref => ({
				statementId: extendedPref.statementId,
				keyId: extendedPref.keyId,
				hideFromUi: extendedPref.hideFromUi,
				isEditable: extendedPref.isEditable,
				fieldTypeId: extendedPref.fieldTypeId,
				defaultFreeTextValue: extendedPref.defaultFreeTextValue,
				defaultSingleSelectValue: extendedPref.defaultSingleSelectValue,
				keyLabel: extendedPref.keyLabel,
				dateCreated: extendedPref.dateCreated || new Date()
			}))
		},
		contactDetailsFields: link.contactDetailsFields ?? []
	}
	if (preferencePageLink.preferencePageLinkId) {
		delete preferencePageLink.brandId
	}
	if (preferencePageLink.authenticationDetails.authTypeId !== authenticationType.ProveEmail && preferencePageLink.authenticationDetails.authTypeId !== authenticationType.ProvideAndProveEmail) {
		delete preferencePageLink.authenticationDetails.proveConfirmationEmailId
	}
	return configApi.post('/api/Links/PreferencePage/Upsert', preferencePageLink)
}

export const upsertPublicPortalLink = link => {
	if (!link.configurationOptions) {
		link.configurationOptions = {}
	}
	if (!link.configurationOptions.ipLimitations) {
		link.configurationOptions.ipLimitations = {
			noOfSubmissionsAllowedFromIp: null,
			ipLimitPeriodInMins: null,
			limitSubmissionsByIpTypeId: false
		}
	}

	if (!link.configurationOptions.encryption) {
		link.configurationOptions.encryption = {
			encryptionKeyId: null
		}
	}
	const publicPortalLink = {
		publicPortalLinkId: link.publicPortalLinkId ? link.publicPortalLinkId : null,
		linkName: link.linkName,
		linkText: link.linkText,
		brandId: link.brandId,
		isEnabled: link.isEnabled ?? true,
		linkFields: [
			...link.linkFields
		],
		configurationOptions: {
			createStandDakIfNoMatch: link.configurationOptions.createStanddakIfNoMatch,
			multiStandDakMatchIsFailure: link.configurationOptions.multiStandDakMatchIsFailure,
			ipLimitations: {
				noOfSubmissionsAllowedFromIp: link.configurationOptions.ipLimitations.noOfSubmissionsAllowedFromIp,
				ipLimitPeriodInMins: link.configurationOptions.ipLimitations.ipLimitPeriodInMins,
				limitSubmissionsByIpTypeId: link.configurationOptions.ipLimitations.limitSubmissionsByIpTypeId ? 2 : 1
			},
			encryption: {
				encryptionKeyId: link.linkFields.some(({ isEncrypted }) => isEncrypted) ? link.configurationOptions.encryption.encryptionKeyId : null
			}
		},
		customDefaultLanguageId: link.customDefaultLanguageId,
		authenticationDetails: {
			authTypeId: link.authenticationDetails.authTypeId,
			standDakAuthField: link.authenticationDetails.standDakAuthField ? link.authenticationDetails.standDakAuthField : null,
			verificationSentMessage: link.authenticationDetails.verificationSentMessage ? link.authenticationDetails.verificationSentMessage : null,
			standDakAuthFieldFriendlyName: link.authenticationDetails.standDakAuthFieldFriendlyName ? link.authenticationDetails.standDakAuthFieldFriendlyName : null,
			verificationAreaText: link.authenticationDetails.verificationAreaText ? link.authenticationDetails.verificationAreaText : null,
			verificationFailedMessage: link.authenticationDetails.verificationFailedMessage ? link.authenticationDetails.verificationFailedMessage : null,
			proveConfirmationEmailId: link.authenticationDetails.proveConfirmationEmailId ? link.authenticationDetails.proveConfirmationEmailId : null
		},
		publicPortalLinkConfig: {
			selectedPublicPortalLandingPageId: link.publicPortalLinkConfig.selectedPublicPortalLandingPageId,
			standDakAuthField: link.publicPortalLinkConfig.standDakAuthField ? link.publicPortalLinkConfig.standDakAuthField : null,
			standDakAuthFieldReplaceValue: link.publicPortalLinkConfig.standDakAuthFieldReplaceValue ? link.publicPortalLinkConfig.standDakAuthFieldReplaceValue : null
		}

	}
	return configApi.post('/api/Links/PublicPortal/Upsert', publicPortalLink)
}

export const saveEncryptionKey = encryptionKey => {
	return configApi.post('api/EncryptionKeys/Upsert', encryptionKey)
}

export const getStandakUrnFields = () => {
	return configApi.get('/api/Links/StandDakUrnFields').then(({ data: { standakUrnFields } }) => ({ data: standakUrnFields }))
}

export const upsertPiiFieldTranslation = translation => {
	return configApi.post('/api/Links/PreferencePage/ContactDetailsTranslation/Upsert', translation)
}

export const encryptParameters = paramEncryptionDTO => {
	return configApi.post('/api/Links/Encrypt', paramEncryptionDTO)
}

// Translations

export const getLinkButtonTranslations = () => {
	return configApi.get('/api/LinksButtons/ButtonTranslations')
}

export const upsertLinkButtonTranslation = translation => {
	return configApi.post('/api/LinksButtons/ButtonTranslations', translation)
}

export const upsertLinkTranslation = translation => {
	return configApi.post('/api/Links/LinkTranslation/Upsert', translation)
}

export const deleteLinkTranslation = (linkId, languageId) => {
	return configApi.delete(`/api/Links/LinkTranslation/Delete?linkId=${linkId}&languageId=${languageId}`)
}
